import { createContext } from 'react';

export type ScrollPosition = {
    x: number;
    y: number;
};

type OverlayContextType = {
    hasOverlays: boolean;
    scrollPosition: ScrollPosition;
    onShow: (el: HTMLElement, scrollPosition: ScrollPosition) => void;
    onHide: (el: HTMLElement) => void;
};

const noop = () => {
    /* noop */
};

const OverlayContext = createContext<OverlayContextType>({
    hasOverlays: false,
    scrollPosition: { x: 0, y: 0 },
    onShow: noop,
    onHide: noop,
});

export default OverlayContext;
