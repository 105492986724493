import { SetupOptions } from '@inertiajs/react/types/createInertiaApp';
import { StrictMode } from 'react';
import { I18nProvider } from 'react-aria-components';
import { IntlProvider } from 'react-intl';

import OverlayContainer from '../components/OverlayContainer/OverlayContainer';
import StyleReset from '../components/StyleReset/StyleReset';
import ThemeProvider from '../components/ThemeProvider/ThemeProvider';
import { ConfirmDialogProvider } from '../hooks/ui/useConfirmDialog';

import HistoryProvider from './components/HistoryProvider/HistoryProvider';
import PageProps from './types/inertia/PageProps';

type InertiaSetup = SetupOptions<HTMLElement, PageProps>;

type RootProps = {
    App: InertiaSetup['App'];
    props: InertiaSetup['props'];
    translations: Record<string, string>;
};

const Root = ({ App, props, translations }: RootProps) => {
    const { dashboardTheme, localization } = props.initialPage.props;
    const { ietfLanguageTag } = localization.currentLocale;

    return (
        <StrictMode>
            <StyleReset>
                <ThemeProvider theme={dashboardTheme}>
                    <HistoryProvider>
                        <IntlProvider
                            messages={translations}
                            locale={ietfLanguageTag}
                            onError={(err) => {
                                // TODO: Probably send this to Sentry
                                console.error(err);
                            }}
                        >
                            {/* This provider is similar to the `IntlProvider` but only applies to react-aria components. */}
                            <I18nProvider locale={ietfLanguageTag}>
                                <OverlayContainer>
                                    <ConfirmDialogProvider context="tenant-dashboard">
                                        <App {...props} />
                                    </ConfirmDialogProvider>
                                </OverlayContainer>
                            </I18nProvider>
                        </IntlProvider>
                    </HistoryProvider>
                </ThemeProvider>
            </StyleReset>
        </StrictMode>
    );
};

export default Root;
