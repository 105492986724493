import { ReactNode, useMemo, useRef, useState } from 'react';

import OverlayContext, { ScrollPosition } from './OverlayContext';

type OverlayContainerProps = {
    children: ReactNode;
};

const OverlayContainer = ({ children }: OverlayContainerProps) => {
    const [overlayEls, setOverlayEls] = useState<HTMLElement[]>([]);
    const scrollPosition = useRef<ScrollPosition>({ x: 0, y: 0 });

    const addOverlay = (
        overlayEl: HTMLElement,
        positionAtOpen: ScrollPosition
    ) => {
        setOverlayEls([...overlayEls, overlayEl]);
        scrollPosition.current = positionAtOpen;
    };

    const removeOverlay = (overlayEl: HTMLElement) => {
        setOverlayEls(overlayEls.filter((el) => el !== overlayEl));

        if (overlayEls.length === 0) {
            scrollPosition.current = { x: 0, y: 0 };
        }
    };

    const contextValue = useMemo(
        () => ({
            onShow: addOverlay,
            onHide: removeOverlay,
            hasOverlays: overlayEls.length > 0,
            scrollPosition: scrollPosition.current,
        }),
        [overlayEls.length, scrollPosition.current]
    );

    return (
        <OverlayContext.Provider value={contextValue}>
            {children}
        </OverlayContext.Provider>
    );
};

export default OverlayContainer;
