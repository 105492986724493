import * as Sentry from '@sentry/react';

const initSentry = (dsn: string, sampleRate: number) => {
    Sentry.init({
        dsn: dsn,
        integrations: [Sentry.replayIntegration()],

        // Prevent Sentry from sending personal identifiable information (PII)
        sendDefaultPii: false,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: sampleRate,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: sampleRate,
        replaysOnErrorSampleRate: 1.0,
    });
};

export default initSentry;
